export const SET_AUTH = "SET_AUTH";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGOUT = "LOGOUT";

export const USER_CHANGE_KEYCHAIN = "USER_CHANGE_KEYCHAIN";
export const USER_ADD_KEYCHAIN = "USER_ADD_KEYCHAIN";

export interface UserChangeKeychainAction {
  type: typeof USER_CHANGE_KEYCHAIN; 
  current: number;
}

export interface UserAddKeychainAction {
  type: typeof USER_ADD_KEYCHAIN ; 
	name: string;
	token: string;
}
export interface SetAuthAction {
  type: typeof SET_AUTH;
  auth: string;
  jwt: string;
}

export interface LoginAction {
  type: typeof LOGIN_SUCCESS;
	payload :{
		firstName: string;
		lastName: string;
		email: string;
		identity: string;

		jwt: string;

		keychain: any;
	}
}

export interface LogoutAction {
  type: typeof LOGOUT;
}

export type LoginActionType = LoginAction | LogoutAction | UserChangeKeychainAction | UserAddKeychainAction;
export type SettingsActionType = SetAuthAction | LoginActionType;
