//@ts-nocheck
import Avial from "@ledr/ts-client";
import AvialType from "@ledr/ts-client";
import { panelLoadAllAction } from "../../store/panels/actions";

import Api from "../wasm";
import overseer from "../../ledr-overseer";

class Panels {
	overseer: overseer;
	api: Api;

	constructor(api, overseer) {
		this.overseer = overseer;
		this.api = api;
	}

	load() {
		return new Promise( async (resolve, reject) => {

			if (!this.overseer.store.getState().user) {
				reject();
			}
			const userIdentityString = this.overseer.store.getState().user.identity;
			const userIdentity = Avial.Utils.Entity.fromString(userIdentityString);

			this.getEntity(userIdentity).then((e) => {
				const user = this.overseer.store.getState().entities[userIdentityString];
				const applicationRegistry =
					user.contents.Attributes["APPLICATION_ATTRIBUTE"][0].Values[0].value;

				this.getEntity(applicationRegistry).then((x) => {
					const app = this.overseer.store.getState().entities[
						Avial.Utils.Entity.toString(applicationRegistry)
					];
					let appWm = app.contents.Properties[0][2].value;
					this.getEntity(appWm).then((y) => {
						//@ts-ignore
						const wm = this.overseer.store.getState().entities[y.result];
						const wmContent =
							wm.contents.Attributes["APPLICATION_ATTRIBUTE"][0].Values[0].value;
						this.overseer.store.dispatch(panelLoadAllAction(Avial.Common.Any_FromString(wmContent)));
						resolve();
					});
				});
			});

		})

	}

	save(userIdentity: AvialType.Entity, data: any, ) {
		return new Promise( async (resolve, reject) => {

			console.log("helloooowwwwSavew")
			const rootEntity = userIdentity;

			let currentAttribute = Avial.Taxonomy.Attribute.APPLICATION;
			let response, appRegistry, wmEntity;

			//	res.locals.avialDo("Clear_Attribute", { entity: rootEntity, attribute: currentAttribute})


			appRegistry = await this.api.session.Get_Attribute({
				auth: this.api.keychain,
				entity: rootEntity,
				attribute: currentAttribute
			}).value;

			console.log(appRegistry)

			wmEntity = await this.api.session.Get_Property({
				auth: this.api.keychain,
				entity: appRegistry,
				key:"WindowManager"
			}).value.value;

			console.log("WM ENTITY : ", wmEntity)
			if (!wmEntity) {
			appRegistry = await this.api.session.Create_Registry({
				auth: this.api.keychain,
				name:"Application",
				key:"Application",
				mode: Avial.Taxonomy.Mode.PERSISTENT
			});

			console.log("HERE")
			console.log(appRegistry)

			await this.api.session.Set_Attribute({
				auth: this.api.keychain,
				entity: rootEntity,
				attribute: currentAttribute,
				value: { type: Avial.Taxonomy.Tag.ENTITY, value: appRegistry.value }
			});

			appRegistry= await this.api.session.Get_Attribute({
				auth: this.api.keychain,
				entity: rootEntity,
				attribute: currentAttribute
			}).value;
			console.log(appRegistry)

			wmEntity = await this.api.session.Create_Entity({
				auth: this.api.keychain,
				klass: Avial.Taxonomy.Class.AVESTERRA,
				subclass: Avial.Taxonomy.Subclass.APPLICATION,
				context: Avial.Taxonomy.Context.AVESTERRA,
				name: "WindowManager",
				key: "WindowManager",
				outlet: Avial.ADAPTER_OBJECT,
				value: { type: Avial.Taxonomy.Tag.INTERCHANGE, value: "{}" },
				method: Avial.Taxonomy.Method.AVESTERRA,
				mode: Avial.Taxonomy.Mode.PERSISTENT
			});

			console.log("CREATED", wmEntity)
			console.log("APPREGI", appRegistry)

			console.log(await this.api.session.Register_Entity({
				auth: this.api.keychain,
				registry: appRegistry,
				entity: wmEntity,
				key: "WindowManager",
				name:"WindowManager" 
			}));
		}

		await this.api.session.Set_Attribute({
			auth: this.api.keychain,
			entity: wmEntity.value,
			attribute: currentAttribute,
			value: {
				type: Avial.Taxonomy.Tag.INTERCHANGE,
				value: Avial.Common.Any_ToString(data)
			}
		});






resolve(null);
/*	
	res.status(200).send({
		result: Entity.toString(wmEntity),
		entities: {
			[Entity.toString(rootEntity)]: res.locals.fetchEntity(rootEntity, res),
			[Entity.toString(appRegistry.value)]: res.locals.fetchEntity(appRegistry.value, res),
			[Entity.toString(wmEntity)]: res.locals.fetchEntity(wmEntity, res),
		},
	});
 */

/* 
	return this.fetchEndpoint(
		`panels/${userIdentity.nid}/${userIdentity.hid}/${userIdentity.uid}/`,
		{
			method: "POST",
			body: Any_ToString(data),
		}
	);
 */
	});
	}
}


export default Panels;
