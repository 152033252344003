import Avial, {AvialType} from "@ledr/ts-client";
import { EntitiesStoreType } from "./reducer";

export const LOAD_ENTITY_DATA_SUCCESS      = "LOAD_ENTITY_DATA_SUCCESS";

export const LOAD_ENTITY_METADATA          = "LOAD_ENTITY_METADATA";
export const LOAD_ENTITY_METADATA_SUCCESS  = "LOAD_ENTITY_METADATA_SUCCESS";
export const LOAD_ENTITY_METADATA_ERROR    = "LOAD_ENTITY_METADATA_ERROR";

export const LOAD_ENTITY_CONTENTS          = "LOAD_ENTITY_CONTENTS";
export const LOAD_ENTITY_CONTENTS_SUCCESS  = "LOAD_ENTITY_CONTENTS_SUCCESS";
export const LOAD_ENTITY_CONTENTS_ERROR    = "LOAD_ENTITY_CONTENTS_ERROR";

export interface LoadEntityDataSuccessAction {
  type: typeof LOAD_ENTITY_DATA_SUCCESS;
  entity: AvialType.Values.V_Entity;
  metadata: AvialType.AvialModel.EntityMetadata;
  contents: AvialType.AvialEntity;
}

//----------------

export interface LoadEntityMetadataAction {
  type: typeof LOAD_ENTITY_METADATA;
  entity: AvialType.Values.V_Entity;
}

export interface LoadEntityMetadataSuccessAction{
  type: typeof LOAD_ENTITY_METADATA_SUCCESS;
  entity: AvialType.Values.V_Entity;
  metadata: AvialType.AvialModel.EntityMetadata;
}

export interface LoadEntityMetadataErrorAction{
  type: typeof LOAD_ENTITY_METADATA_ERROR;
  entity: AvialType.Values.V_Entity;
	error: AvialType.Exception;
}


export interface LoadEntityContentsAction{
  type: typeof LOAD_ENTITY_CONTENTS;
  entity: AvialType.Values.V_Entity;
}

export interface LoadEntityContentsSuccessAction{
  type: typeof LOAD_ENTITY_CONTENTS_SUCCESS;
  entity: AvialType.Values.V_Entity;
  contents: AvialType.AvialEntity;
}

export interface LoadEntityContentsErrorAction{
  type: typeof LOAD_ENTITY_CONTENTS_ERROR;
  entity: AvialType.Values.V_Entity;
	error: AvialType.Exception;
}

//----------------

export type LoadEntityMetadataType =
  | LoadEntityMetadataAction 
  | LoadEntityMetadataSuccessAction 
  | LoadEntityMetadataErrorAction;

export type LoadEntityContentsType =
  | LoadEntityContentsAction 
  | LoadEntityContentsSuccessAction 
  | LoadEntityContentsErrorAction;

//----------------

export type EntityActionType =
  | LoadEntityDataSuccessAction
  | LoadEntityMetadataType
  | LoadEntityContentsType ;

