import Avial from "@ledr/ts-client";
import {AvialType} from "@ledr/ts-client";

import {
	LOAD_ENTITY_DATA_SUCCESS,
	LOAD_ENTITY_METADATA,
	LOAD_ENTITY_METADATA_SUCCESS,
	LOAD_ENTITY_METADATA_ERROR,
	LOAD_ENTITY_CONTENTS,
	LOAD_ENTITY_CONTENTS_SUCCESS,
	LOAD_ENTITY_CONTENTS_ERROR, 
	EntityActionType
} from "./types";


export interface I_EntityStore
{
	isLoadingMetadata: boolean;
	isLoadingContents: boolean;

	metadata?: AvialType.AvialModel.EntityMetadata;
	contents?: AvialType.AvialEntity;

	errorMetadata?: AvialType.Exception;
	errorContents?: AvialType.Exception;
}

export interface EntitiesStoreType {
	[Key: string]: I_EntityStore;
}

let initialState: EntitiesStoreType = {};
/*
function insertEntity(
	state: EntitiesStoreType,
	payload: any
): EntitiesStoreType {
	let newState = { ...state };

	for (const entity in payload) {
		// REGISTER ENTITY TWICE, AT HIS ABSOLUTE NAME AND HIS REDIRECTION

		// ABSOLUTE
		newState[payload[entity].metadata.Fields.Entity] = {
			metadata: payload[entity].metadata,
			contents:
			Avial.Comprehensive.EntityFormat_ToAvialModel(
				payload[entity].contents
			),

			isLoadingMetadata: false,
			isLoadingContents: false,
			errorMetadata: undefined,
			errorContents: undefined,
		};

		// REDIRECTION
		newState[entity] = newState[payload[entity].metadata.Fields.Entity];
	}
	return newState;
}
 */

function loadEntityMetadata(state: EntitiesStoreType, entity: AvialType.Values.V_Entity): EntitiesStoreType {
	let entityString = entity.toString();
	return {
		...state,
		[entityString]: {
			...state[entityString],
			isLoadingMetadata: true,
		}
	}
}

function loadEntityContents(state: EntitiesStoreType, entity: AvialType.Values.V_Entity): EntitiesStoreType {
	let entityString = entity.toString();
	return {
		...state,
		[entityString]: {
			...state[entityString],
			isLoadingContents: true,
		}
	}
}


function loadEntityDataSuccess(
	state: EntitiesStoreType,
	entity: AvialType.Values.V_Entity,
	metadata: AvialType.AvialModel.EntityMetadata,
	contents: AvialType.AvialEntity
): EntitiesStoreType {

	let entityString = entity.toString();

	// THREAT REDIRECTIONS 
	// REGISTER BOTH ABSOLUTE AND RELATIVE
	let toStore = {
			...state[entityString],
			metadata: metadata,
			contents: contents,
			isLoadingMetadata: false,
			isLoadingContents: false,
		};

	return {
		...state,
		[entityString]           : toStore,
		[metadata.Fields.Entity] : toStore
	}
}

function loadEntityMetadataSuccess(state: EntitiesStoreType, entity: AvialType.Values.V_Entity, metadata: AvialType.AvialModel.EntityMetadata): EntitiesStoreType {
	let entityString = entity.toString();
	return {
		...state,
		[entityString]: {
			...state[entityString],
			metadata: metadata,
			isLoadingMetadata: false,
		}
	}
}

function loadEntityContentsSuccess(state: EntitiesStoreType, entity: AvialType.Values.V_Entity, contents: AvialType.AvialEntity): EntitiesStoreType {
	let entityString = entity.toString();
	return {
		...state,
		[entityString]: {
			...state[entityString],
			contents: contents,
			isLoadingContents: false,
		}
	}
}

function loadEntityMetadataError(state: EntitiesStoreType, entity: AvialType.Values.V_Entity, error: AvialType.Exception): EntitiesStoreType {
	let entityString = entity.toString();
	return {
		...state,
		[entityString]: {
			...state[entityString],
			errorMetadata: error,
			isLoadingMetadata: false,
		}
	}
}

function loadEntityContentsError(state: EntitiesStoreType, entity: AvialType.Values.V_Entity, error: AvialType.Exception): EntitiesStoreType {
	let entityString = entity.toString();
	return {
		...state,
		[entityString]: {
			...state[entityString],
			errorContents: error,
			isLoadingContents: false,
		}
	}
}

function entitiesStoreReducer(
	state = initialState,
	action: EntityActionType 
): EntitiesStoreType {
	switch (action.type) {


		case LOAD_ENTITY_DATA_SUCCESS:
			return loadEntityDataSuccess(state, action.entity, action.metadata, action.contents);

		case LOAD_ENTITY_METADATA:
			return loadEntityMetadata(state, action.entity);
		case LOAD_ENTITY_CONTENTS:
			return loadEntityContents(state, action.entity);

		case LOAD_ENTITY_METADATA_SUCCESS:
			return loadEntityMetadataSuccess(state, action.entity, action.metadata);
		case LOAD_ENTITY_CONTENTS_SUCCESS:
			return loadEntityContentsSuccess(state, action.entity, action.contents);

		case LOAD_ENTITY_METADATA_ERROR:
			return loadEntityMetadataError(state, action.entity, action.error);
		case LOAD_ENTITY_CONTENTS_ERROR:
			return loadEntityContentsError(state, action.entity, action.error);

		default:
			return state;
	}
}

export default entitiesStoreReducer;
