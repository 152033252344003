import {
	LoginAction, LOGIN_SUCCESS,
	LogoutAction, LOGOUT,
	UserChangeKeychainAction, USER_CHANGE_KEYCHAIN,
	UserAddKeychainAction, USER_ADD_KEYCHAIN
} from "./types";

export const userChangeKeychainAction = (current: number): UserChangeKeychainAction => ({
	type: USER_CHANGE_KEYCHAIN,
	current : current
});

export const userAddKeychainAction= (name, token): UserAddKeychainAction => ({
type: USER_ADD_KEYCHAIN,
	name,
	token
});

export const loginAction = (payload): LoginAction => ({
	type: LOGIN_SUCCESS,
	payload: payload
});

export const logoutAction = (): LogoutAction => ({
	type: LOGOUT,
});
