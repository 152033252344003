import { I_Category } from "@ledr/instruments";
import { I_Panels, I_PanelsStore } from "./reducer";

export const PANEL_LOAD_ALL = "PANEL_LOAD_ALL";

export const PANEL_NEW = "PANEL_NEW";
export const PANEL_CHANGE = "PANEL_CHANGE";
export const PANEL_DELETE = "PANEL_DELETE";
export const PANEL_REMOVE_CATEGORY = "PANEL_REMOVE_CATEGORY";

export const CATEGORY_NEW = "CATEGORY_NEW";
export const CATEGORY_CHANGE = "CATEGORY_CHANGE";
export const CATEGORY_DELETE = "CATEGORY_DELETE";

export interface PanelLoadAllAction {
  type: typeof PANEL_LOAD_ALL;
  payload: { panelsStore: I_PanelsStore };
}

export interface NewPanelAction {
  type: typeof PANEL_NEW;
  payload: { hash: string };
}

export interface PanelChangeAction {
  type: typeof PANEL_CHANGE;
  payload: { panel: I_Panels };
}

export interface PanelDeleteAction {
  type: typeof PANEL_DELETE;
  payload: { hash: string };
}

export interface PanelRemoveCategoryAction {
  type: typeof PANEL_REMOVE_CATEGORY;
  payload: {
    panelId: string;
    categoryId: string;
  };
}

export interface NewCategoryAction {
  type: typeof CATEGORY_NEW;
  payload: { hash: string };
}

export interface CategoryChangeAction {
  type: typeof CATEGORY_CHANGE;
  payload: {
    categoryId: string;
    newCategory: I_Category[];
  };
}

export interface CategoryDeleteAction {
  type: typeof CATEGORY_DELETE;
  payload: { hash: string };
}

export type PanelActionType =
  | PanelLoadAllAction
  | NewPanelAction
  | PanelChangeAction
  | PanelDeleteAction
  | PanelRemoveCategoryAction
  | NewCategoryAction
  | CategoryChangeAction
  | CategoryDeleteAction;
