import { combineReducers } from "redux";

import entitiesStoreReducer from "./entities/reducer";
import logStoreReducer from "./log/reducer";
import panelsStoreReducer from "./panels/reducer";
import settingsStoreReducer from "./user/reducer";
import endpointStoreReducer from "./endpoint/reducer";
import devicesStoreReducer from "./devices/reducer";

export const rootReducer = combineReducers({
	user     : settingsStoreReducer,
	devices  : devicesStoreReducer,
	entities : entitiesStoreReducer,
	logs     : logStoreReducer,
	panels   : panelsStoreReducer,
	endpoints: endpointStoreReducer,
});
