import Avial, {AvialType} from "@ledr/ts-client"

export async function getSpace(space:AvialType.Values.V_Entity, level, value)
{
	return new Promise(async (resolve, reject) =>
	{

		let opts = {
			entity: space.value,
			method: Avial.Taxonomy.Method.RETRIEVE,
			authorization: this.keychain,
			parameter: 2n,// level,
			value: { type: "INTERCHANGE", value: "["+value.toString()+"]"},
		}
		console.log(opts)
		this.session.invokeEntity(opts)
			.then((data) => {
				if (data.isError())
					reject(data.value);
				else
					resolve(JSON.parse(data?.value?.value)?.Properties);
			})
			.catch((err) => {
				reject(err);
			});

/*
		this.session.Retrieve_Space({
			auth  : this.keychain,
			space,
			level,
			volume: value,
		})
			.then((data) => {
				if (data.isError())
					reject(data.value);
				else
					resolve(JSON.parse(data?.value?.value)?.Properties);
			})
			.catch((err) => {
				reject(err);
			});
*/
	});
}

export async function addSpace(space, coordinate, key, klass, category)
{
	return new Promise(async (resolve, reject) =>
	{
		console.log("OBSOLETE CALL TO ADD SPACE API")
		/*
		console.log(coordinate)
		this.session.invokeEntity({
			method: Avial.Taxonomy.Method.INCLUDE,
			context	: Avial.Taxonomy.Context.AVESTERRA,
			entity : space.value,
			auxiliary: new Avial.Values.V_Entity("<0|0|7>").value,
//		name: name,
			key: key,
			value     : Avial.Utils.Value.fromTagAndString(Avial.Taxonomy.Tag.INTERCHANGE, JSON.stringify(coordinate) ),
			parameter: 0n, // WARNING THIS DON'T WORK

//		class: klass,
//		category: category,
//		state,
//		condition,

			authorization: this.keychain,
		})
			.then((data) => {
				console.log(data)
				if (data.isError()) reject();
				else resolve("");
			})
			.catch((err) =>
			{
				console.log("err", err);
				reject(err);
			});
*/
	});
}


export async function removeSpace(space, entity, level)
{
	return new Promise(async (resolve, reject) =>
	{
		console.log(entity);
		this.session.Invoke_Entity({
			auth: this.keychain,
			entity : space,
			method: Avial.Taxonomy.Method.EXCLUDE,
			parameter: level,
			auxiliary: entity,
		})
		/*
		this.session.Exclude_Entity({
			auth: this.keychain,
			space,
			entity,
			level
		})
			.then((data) =>
			{
				if (data.isError()) reject();
				else resolve("");
			})
			.catch((err) =>
			{
				console.log("err", err);
				reject(err);
			});
		*/
	});
}

