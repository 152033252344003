export const ENDPOINT_NEW = "ENDPOINT_NEW";
export const ENDPOINT_CHANGE_STATUS = "ENDPOINT_CHANGE_STATUS";

export interface NewEndpointAction {
  type: typeof ENDPOINT_NEW;
	payload: {
		name: string;
		host: string;
		status: string;
	}
}


export interface EndpointChangeStatusAction {
  type: typeof ENDPOINT_CHANGE_STATUS;
	payload: {
		name: string;
		status: string;
	}
}

export type EndpointActionsType = NewEndpointAction | EndpointChangeStatusAction;
