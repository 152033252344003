import Avial from "@ledr/ts-client";

export async function login(email:string, password:string)
{
	return new Promise(async (resolve, reject) =>
	{
		let user = {
			firstName: "-FirstName-",
			lastName : "-LastName-",
			email    : email,
			identity : "<0|0|0>",
		};

		const keychain = await this.session.sessionAuthenticate({

			//identity_key: "LEDR_ADMIN",
			//password: "avesterra20057",
			identity_key    : user.email,
			password        : password,
			compartment_keys: ["orchestra"],
			authorization: 0x2n,//verify authorizatio
			/*
			compartment_keys: ["orchestra"],
			authorization: 0x2n,
			*/
		});
		console.log("LOGIN TRY", keychain)

		if (keychain.isError())
		{
			reject(keychain.value);
			return;
		}

		let newKeychain = {
			...user,
			keychain: {
				...keychain.value,
				current : 0,
				accesses: 
					keychain.value.accesses.map((e) => ({
					...e,
					token: Avial.Utils.Authorization.toString(e.token),
				})),
			},
		};
		resolve(newKeychain);
	});
}

/*
function signup(firstName: string, lastName: string, email: string, password: string) {
		return this.fetchEndpoint("login/signup", {
			method: "POST",
			body: JSON.stringify({ firstName, lastName, email, password }),
		});
	}

function confirmEmail(email: string, token: string) {
		return this.fetchEndpoint("login/confirmEmail", {
			method: "POST",
			body: JSON.stringify({ email, token }),
		});
	}

function forgotPassword(email: string) {
		return this.fetchEndpoint("login/forgotPassword", {
			method: "POST",
			body: JSON.stringify({ email }),
		});
	}

function resetPassword(email: string, token: string, password: string) {
		return this.fetchEndpoint("login/resetPassword", {
			method: "POST",
			body: JSON.stringify({ email, token, password }),
		});
	}
 */
