//@ts-expect-error
import { notif } from "@ledr/layout";

import Avial, {AvialType } from "@ledr/ts-client";

import overseer from "../ledr-overseer";
import { addLogAction } from "../store/log/actions";
import { login } from "./login/login";
import { getSpace, addSpace, removeSpace } from "./space/space";
import Panels from "./panels/panels";

import {
	loadEntityContentsSuccessAction,
	loadEntityMetadataSuccessAction,
} from '../store/entities/actions';

import {
	endpointChangeStatusAction,
	newEndpointAction,
} from "../store/endpoint/actions";

class Api
{
	overseer:overseer;
	store;
	name;
	scheme;
	host;
	port;
	session: AvialType.AvSession;
	keychain;
	interval;
	sslPem;

	login:typeof login      = login;
	panels:Panels;

	getSpace:typeof getSpace = getSpace;
	addSpace:typeof addSpace = addSpace;
	removeSpace:typeof removeSpace = removeSpace;

	constructor(opts, overseer)
	{
		this.overseer = overseer;
		this.name = opts.name;
		this.host = opts.host;
		this.port = opts.port;
		this.sslPem = opts.sslPem;
		this.scheme = opts.scheme;

		this.session = null;
		this.keychain = null;
		this.interval = null;

		console.info(`API - ['${this.name}'] ${this.scheme} ${this.host}:${this.port} mounted`);

		this.overseer.store.dispatch(newEndpointAction({ name: opts.name, host: opts.host }));
		this.connect();
		this.panels = new Panels(this, overseer);
	}

	connect()
	{
		let session = Avial.Session(
			this.host,
			this.scheme,
			4,
			0x1n,
			this.port,
			false,
			true,
			this.logger.bind(this)
		);


		session.initialize().then(async (result)=> {
			this.session = session;
			this.session.pool.on("change", (pool)=>{


				pool?.channels?.forEach((c, i) => {
					//@ts-ignore
					if (!c && this.overseer.store.getState().endpoints[0]?.channels?.[i]?.open === true)
						notif.error(`Socket [${i}] disconnected`);
					//@ts-ignore
					if (c && !this.overseer.store.getState().endpoints[0]?.channels?.[i]?.open)
						notif.success(`Socket  [${i}] connected`);
				})

				this.overseer.store.dispatch(endpointChangeStatusAction({
					name: this.name,
					status: "open",
					channels: pool.channels.map(c => ({
						open: c ? true : false,
						in_use : c?.in_use
					}))
				}));

			})

			this.overseer.store.subscribe(() =>
				{
					let keychain = this.overseer.store.getState().user.keychain;
					if (!keychain) return
					this.keychain = Avial.Utils.Authorization.fromString(keychain.accesses[keychain.current].token);
				});

			if (this.overseer.store.getState().user.keychain)
			{
				let keychain = this.overseer.store.getState().user.keychain;
				if (!keychain) return
				this.keychain = Avial.Utils.Authorization.fromString(keychain.accesses[keychain.current].token);


			}


		});
	}

	logger(args) {
		this.overseer.store.dispatch(addLogAction({
			timestamp: Date.now(),
			type     : args?.res?.error ? "error" : "debug",
			channel  : "hgtp",
			msg: args,
			statusCode: (args?.res?.error) ? args.res.error : null
		}));
	}

	async getEntity(entity)
	{
		return this.get(`entity/${entity.nid}/${entity.hid}/${entity.uid}`);
	}

	async adapt()
	{

		// MUST ACTIVATE BEFORE

			console.log("RUN ADAPT ")
		let entity  = new Avial.Values.V_Entity("<0|0|100850>").value;
    while (true) {
			console.log("ADAPT LOOP")
      const result_adapt = await this.session.adaptOutlet({
        outlet: entity,
        authorization: this.keychain,
        //@ts-ignore
        callback: (...args)=>{
					console.log(args)
					return AvialType.AvSuccess({ type: 'INTERCHANGE', value: `{"error": "throwed"}` });
				},
//this.callback.bind(this)
      });

      if (result_adapt.isError()) {
        //this.log.error('Args echo test adapter failure');
        await this.session.syncOutlet({ outlet: entity, authorization: this.keychain });
      }
    }
	}


	async invoke(entity:AvialType.Entity,  obj:object)
	{
		return new Promise(async (resolve, reject) =>
			{
				//@ts-ignore
				const invokeResult = await this.session.invokeEntity({
					authorization      : this.keychain,
					entity    : entity,
					value     : Avial.Utils.Value.fromTagAndString(Avial.Taxonomy.Tag.INTERCHANGE, Avial.Common.Any_ToString(obj)),
					precedence: Avial.Taxonomy.Precedence.GRAPH,
					timeout   : 1000n,
				});
				invokeResult.isError() ?  reject(invokeResult) : resolve(invokeResult);
			});
	}

	async get(e)
	{
		let entity = e;
		if (typeof e === "string")
		{
			entity = {
				nid: Number(e.split("/")[1]),
				hid: Number(e.split("/")[2]),
				uid: BigInt(e.split("/")[3]),
			};
		}
		return new Promise(async (resolve, reject) =>
			{
				//@ts-ignore
				const metadata = await this.session.fetchEntity({ authorization: this.keychain, entity: entity.value });
				const contents = await this.session.retrieveEntity({ authorization: this.keychain, entity: entity.value });

				if (metadata.isError() || contents.isError())
				{
					reject({
						metadata: metadata.value,
						contents: contents.value,
					});
					return;
				}



				let entityPID;
				try{
					entityPID = Avial.Common.Any_FromString(metadata.value.value).Fields.Entity;
				}catch(e){
					console.log(e)
					reject();
					return;
				}
				this.overseer.store.dispatch(
					loadEntityMetadataSuccessAction(
						new Avial.Values.V_Entity(
							entityPID
						) ,
						Avial.Common.Any_FromString(metadata.value.value)
					)
				)

				this.overseer.store.dispatch(
					loadEntityContentsSuccessAction(
						new Avial.Values.V_Entity(
							entity
						),
						new Avial.AvialEntity(contents.value.value)
					)
				)
				resolve({
					metadata: Avial.Common.Any_FromString(metadata.value.value),
					contents: new Avial.AvialEntity(contents.value.value)
				});
			});
	}

	/*
	post(path: string, data: any) {
		console.log("==========================");
		let bb = JSON.stringify({
			metadata: data.metadata,
			registry: data.registry,
			content: Comprehensive.EntityFormat_ToString(
				FormatAvial.EntityFormat_ToComprehensive(data.content)
			),
		});

		console.log("==========================");
		return this.fetchEndpoint(path, {
			method: "POST",
			body: bb,
		});
	}

	put(path: string, data: any) {
		return this.fetchEndpoint(path, {
			method: "PUT",
			body: Comprehensive.EntityFormat_ToString(
				FormatAvial.EntityFormat_ToComprehensive(data)
			),
		});
	}

	del(path: string) {
		return this.fetchEndpoint(path, {
			method: "DELETE",
		});
	}

	//----------------------------------------------------------------

	loadPanels() {
		if (!this.overseer.store.getState().user) return;

		const userIdentityString = this.overseer.store.getState().user
			.identity;
		const userIdentity = Entity.fromString(userIdentityString);

		this.getEntity(userIdentity).then((e) => {
			const user = this.overseer.store.getState().entities[userIdentityString];
			const applicationRegistry =
				user.contents.Attributes["APPLICATION_ATTRIBUTE"][0].Values[0].value;

			this.getEntity(applicationRegistry).then((x) => {
				const app = this.overseer.store.getState().entities[
					Entity.toString(applicationRegistry)
				];
				let appWm = app.contents.Properties[0][2].value;
				this.getEntity(appWm).then((y) => {
					//@ts-ignore
					const wm = this.overseer.store.getState().entities[y.result];
					const wmContent =
						wm.contents.Attributes["APPLICATION_ATTRIBUTE"][0].Values[0].value;
					this.overseer.store.dispatch(panelLoadAllAction(JSON.parse(wmContent)));
				});
			});
		});
	}

	savePanel(userIdentity: AvialType.Entity, data: any) {
		return this.fetchEndpoint(
			`panels/${userIdentity.nid}/${userIdentity.hid}/${userIdentity.uid}/`,
			{
				method: "POST",
				body: JSON.stringify(data),
			}
		);
	}

//- FILE ---------------------------------------------------------

	uploadFile(formData: FormData) {
		return this.fetchEndpoint(
			"file/upload",
			{
				method: "POST",
				body: formData,
			},
			true
		);
	}

	downloadFile(file: AvialType.File) {
		return this.fetchEndpoint(`file/${file.nid}/${file.hid}/${file.uid}`, {
			method: "GET",
		});
	}

//- EXECUTOR -----------------------------------------------------

	execute(template: AvialType.Template, outlet: AvialType.Outlet) {
		return this.fetchEndpoint(
			`execute/${template.nid}/${template.hid}/${template.uid}`,
			{
				method: "POST",
				body: JSON.stringify({
					template: template,
					outlet: outlet,
				}),
			}
		);
	}
	*/

createEntity = async (data: Object, ___?:any):Promise<any> => new Promise(
	async(resolve, reject) => {


		let res = await this.session.invokeEntity({
			entity: new Avial.Values.V_Entity("<14>").value,
			method: "CREATE_METHOD",
			//@ts-ignore
			name: data.content.Name,
			//@ts-ignore
			key : data.content.Key,
			//@ts-ignore
			context: data.metadata.context,
			//@ts-ignore
			class: data.metadata.class,
			//@ts-ignore
			category: data.metadata.category,
			authorization: this.keychain,
		})

		if (!res?.value?.value)
			reject();

		let entity = new Avial.Values.V_Entity(res.value.value)
		resolve(entity);
});

storeEntity = async (entity, content):Promise<any> => new Promise(
	async(resolve, reject) => {
		let res2 = await this.session.storeEntity({
			entity: entity.value,
			//@ts-ignore
			value: {type: "INTERCHANGE", value: JSON.stringify(content) } ,
			mode: 2,
			authorization: this.keychain,
		})
		resolve(res2)
});

registerEntity= async (registry, entity, name, key):Promise<any> => new Promise(
	async(resolve, reject) => {
		let res3 = await this.session.invokeEntity({
				entity        : registry.value,
        method        : "INCLUDE_METHOD",
        aspect        : 4, //	"ASPECT_PROPERTY", // must be set at ASPECT.PROPERTY
        name          : name,
        key           : key,
				value: { type: "ENTITY", value: entity.value},
				authorization: this.keychain,
		})
		resolve(res3)
});

purgeEntity = async (entity):Promise<any> => new Promise(
	async(resolve, reject) => {
		let res3 = await this.session.invokeEntity({
				entity        : entity.value,
        method        : "PURGE_METHOD",
				authorization: this.keychain,
		})
		resolve(res3)
});

changeEntity = async (entity, data):Promise<any> => new Promise(
	async(resolve, reject) => {
		let res3 = await this.session.changeEntity({
				entity        : entity.value,
				name: data.name,
				key: data.key,
				class: data.class,
				category: data.category,
				context: data.context,
				authorization: this.keychain,
		})
		resolve(res3)
});

uploadFile = async (_?:any, __?:any, ___?:any):Promise<any> => new Promise(() => { alert("notImplemented"); });



put = async (_?:any, __?:any, ___?:any):Promise<any> => new Promise(() => { alert("notImplemented PUT"); });
execute = async (_?:any, __?:any, ___?:any):Promise<any> => new Promise(() => { alert("notImplemented"); });
del = async (_?:any, __?:any, ___?:any):Promise<any> => new Promise(() => { alert("notImplemented"); });
signup = async (_?:any, __?:any, ___?:any, ____?:any):Promise<any> => new Promise(() => { alert("notImplemented"); });
forgotPassword = async (_?:any, __?:any, ___?:any, ____?:any):Promise<any> => new Promise(() => { alert("notImplemented"); });
confirmEmail = async (_?:any, __?:any, ___?:any):Promise<any> => new Promise(() => { alert("notImplemented"); });
resetPassword = async (_?:any, __?:any, ___?:any):Promise<any> => new Promise(() => { alert("notImplemented"); });
}

export default Api;
