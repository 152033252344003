import {
	ENDPOINT_NEW, NewEndpointAction,
	ENDPOINT_CHANGE_STATUS, EndpointChangeStatusAction
} from "./types";

export const newEndpointAction = (payload): NewEndpointAction => ({
  type: ENDPOINT_NEW,
	payload: payload
});


export const endpointChangeStatusAction= (payload): EndpointChangeStatusAction => ({
  type: ENDPOINT_CHANGE_STATUS,
	payload: payload
});

