import Avial from "@ledr/ts-client";
import {AvialType} from "@ledr/ts-client";

import {
	LoadEntityDataSuccessAction,     LOAD_ENTITY_DATA_SUCCESS,
	LoadEntityMetadataAction,        LOAD_ENTITY_METADATA,
	LoadEntityMetadataSuccessAction, LOAD_ENTITY_METADATA_SUCCESS ,
	LoadEntityMetadataErrorAction,   LOAD_ENTITY_METADATA_ERROR,
	LoadEntityContentsAction,        LOAD_ENTITY_CONTENTS,
	LoadEntityContentsSuccessAction, LOAD_ENTITY_CONTENTS_SUCCESS ,
	LoadEntityContentsErrorAction,   LOAD_ENTITY_CONTENTS_ERROR,

} from "./types";
import { EntitiesStoreType } from "./reducer";


export const loadEntityDataSuccessAction = (
	entity: AvialType.Values.V_Entity,
	metadata: AvialType.AvialModel.EntityMetadata,
	contents: AvialType.AvialEntity
): LoadEntityDataSuccessAction => ({
	type: LOAD_ENTITY_DATA_SUCCESS,
	entity: entity,
	metadata,
	contents
});




export const loadEntityMetadataAction = (
	entity: AvialType.Values.V_Entity
): LoadEntityMetadataAction => ({
	type: LOAD_ENTITY_METADATA,
	entity: entity,
});


export const loadEntityMetadataSuccessAction = (
	entity: AvialType.Values.V_Entity,
	metadata: AvialType.AvialModel.EntityMetadata
): LoadEntityMetadataSuccessAction => ({
	type: LOAD_ENTITY_METADATA_SUCCESS,
	entity: entity,
	metadata
});


export const loadEntityMetadataErrorAction = (
	entity: AvialType.Values.V_Entity,
	error: AvialType.Exception
): LoadEntityMetadataErrorAction => ({
	type: LOAD_ENTITY_METADATA_ERROR,
	entity: entity,
	error
});

export const loadEntityContentsAction = (
	entity: AvialType.Values.V_Entity
): LoadEntityContentsAction => ({
	type: LOAD_ENTITY_CONTENTS,
	entity
});


export const loadEntityContentsSuccessAction = (
	entity: AvialType.Values.V_Entity,
	contents: AvialType.AvialEntity
): LoadEntityContentsSuccessAction => ({
	type: LOAD_ENTITY_CONTENTS_SUCCESS,
	entity,
	contents
});


export const loadEntityContentsErrorAction = (
	entity: AvialType.Values.V_Entity,
	error: AvialType.Exception
): LoadEntityContentsErrorAction => ({
	type: LOAD_ENTITY_CONTENTS_ERROR,
	entity,
	error
});




