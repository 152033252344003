import {
	ENDPOINT_NEW,
	ENDPOINT_CHANGE_STATUS,
	EndpointActionsType,
} from "./types";

export interface I_Endpoint {
	name: string;
	host: string;
	status: "close" | "open";
}

type T_EndpointStoreType = I_Endpoint[];

let initialState: T_EndpointStoreType = [];



function changeEndpointStatus(state, payload){
	return state.map(e =>
		e.name !== payload.name
		? e
		: {
			...e,
			...payload
			}
	);
};



function newEndpoint(state, payload){
	if (state.find(e => e.name === payload.name))
		return (state)
	return [
		...state,
		{
			...payload,
			status: "close"
		}
	]
};

function settingsStoreReducer( state = initialState, action: EndpointActionsType): T_EndpointStoreType {
  switch (action.type) {
    case ENDPOINT_NEW:
      return newEndpoint(state, action.payload);
    case ENDPOINT_CHANGE_STATUS:
      return changeEndpointStatus(state, action.payload);
    default:
      return state;
  }
}

export default settingsStoreReducer;
