import { T_TwmArbo, T_FwmWindows } from "@ledr/instruments";
import { I_Panels, I_PanelsStore } from "./reducer";
import {
  PANEL_LOAD_ALL,
  PANEL_NEW,
  PANEL_CHANGE,
  PANEL_DELETE,
  PANEL_REMOVE_CATEGORY,
  CATEGORY_NEW,
  CATEGORY_CHANGE,
  CATEGORY_DELETE,
  PanelLoadAllAction,
  NewPanelAction,
  PanelChangeAction,
  PanelDeleteAction,
  PanelRemoveCategoryAction,
  CategoryChangeAction,
  NewCategoryAction,
  CategoryDeleteAction,
} from "./types";

export const panelLoadAllAction = (
  panelsStore: I_PanelsStore
): PanelLoadAllAction => ({
  type: PANEL_LOAD_ALL,
  payload: { panelsStore },
});

export const newPanelAction = (hash: string): NewPanelAction => ({
  type: PANEL_NEW,
  payload: { hash },
});

export const panelChangeAction = (panel: I_Panels): PanelChangeAction => ({
  type: PANEL_CHANGE,
  payload: { panel },
});

export const panelDeleteAction = (hash: string): PanelDeleteAction => ({
  type: PANEL_DELETE,
  payload: { hash },
});

export const panelRemoveCategoryAction = (
  panelId: string,
  categoryId: string
): PanelRemoveCategoryAction => ({
  type: PANEL_REMOVE_CATEGORY,
  payload: { panelId, categoryId },
});

export const newCategoryAction = (hash: string): NewCategoryAction => ({
  type: CATEGORY_NEW,
  payload: { hash },
});

export const categoryChangeAction = (
  categoryId,
  newCategory
): CategoryChangeAction => ({
  type: CATEGORY_CHANGE,
  payload: {
    categoryId,
    newCategory,
  },
});

export const categoryDeleteAction = (hash: string): CategoryDeleteAction => ({
  type: CATEGORY_DELETE,
  payload: { hash },
});
