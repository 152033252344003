export const SET_KEY_BINDING = "SET_KEY_BINDING";
export const CHANGE_KEY_BINDING = "CHANGE_KEY_BINDING";

export interface SetKeyBindingAction
{
	type:typeof SET_KEY_BINDING;
	key:string;
	action:string;
	eventType:string;
}

export interface ChangeKeybindingAction
{
	type:typeof CHANGE_KEY_BINDING;
	key:string;
	action:string;
	eventType:string;
}

export type KeyboardActionType = SetKeyBindingAction | ChangeKeybindingAction;

export type DevicesActionType = KeyboardActionType;
