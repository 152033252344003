import {
	DevicesActionType,
	SET_KEY_BINDING,
	CHANGE_KEY_BINDING,
} from "./types";

export interface DevicesStoreType
{
	keyboard?:any;
}

let initialState:DevicesStoreType  = {
	keyboard: [

		{
			key: "n", action: "showNewWindow", eventType: "onPress",
		},
		{
			key: "d", action: "showDebug", eventType: "toogle",
		},
		{
			key: "l", action: "showInstrumentsLinker", eventType: "onPress",
		},
		{
			key: "Backspace", action: "deleteLink", eventType: "onPress",
		},

	],
};

function devicesStoreReducer(state = initialState, action:DevicesActionType):DevicesStoreType
{
	switch (action.type)
	{
		case SET_KEY_BINDING:
			return {
				...state,
				keyboard: [
					...state.keyboard,
					{
						key      : action.key,
						action   : action.action,
						eventType: action.eventType,
					},
				],
			};
		case CHANGE_KEY_BINDING:
			return {
				...state,
				keyboard: [
					...state.keyboard.map((kb) => (kb.action === action.action && kb.eventType === action.eventType)
						? {
							key      : action.key,
							action   : action.action,
							eventType: action.eventType,
						}
						: kb),
				],
			};
		default:
			return state;
	}
}

export default devicesStoreReducer;
